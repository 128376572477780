/* Ensure the container is relatively positioned */
div.relative {
    position: relative;
  }
  
  .img1 {
    display: block;
    width: 100%;
    height: auto;
    position: relative; /* Keeps img below the SVG */
  }
  
  .svg1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10; /* Ensures SVG is on top of the img */
  }
  
  .tooltip {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 5px;
    border-radius: 4px;
    font-size: 12px;
    pointer-events: none;
    z-index: 10;
  }
  
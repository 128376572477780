
  
  .count {
    font-size: 24px;
    font-weight: bold;
    margin: 0;
  }
  

  
  .row-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

@media screen and (max-width: 980px) {
  
  .row-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.loader-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loader-image {
  height: 180px;
  width: 180px;
  display: flex;
  align-items: center;
  animation: flip 2s infinite; /* Add the flip animation here */
}

@keyframes flip {
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(180deg);
  }
  100% {
    transform: rotateY(0deg);
  }
}

/* Media query for responsiveness */
@media (max-width: 768px) {
  .loader-image {
    /* Adjust styles for smaller screens if needed */
    height: 75px;
    width: 75px;
  }
}
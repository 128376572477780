/* BoxContainer.css */
.boxxx-container {
    display: flex;
    flex-wrap: wrap;
  }
  
  .boxxx {
    position: relative;
    flex: 1;
    margin: 10px;
    padding: 20px;
    background-color: rgb(220, 243, 250);
    border-radius: 20px;
  }

  .boxxx::before {
    content: "";
    position: absolute;
    inset: -2px;
    border-radius: 20px;
    border: 2px solid transparent;
    box-shadow: 9px 7px 20px 0px rgb(147 113 113);
    background: conic-gradient(
      from 83.41deg at 50.24% 50.24%, 
      rgb(37, 193, 111) -36.7deg, 
      rgb(239, 199, 94) 13.28deg, 
      rgb(255, 156, 35) 57.71deg, 
      rgb(255, 73, 44) 98.29deg, 
      rgb(189, 99, 196) 135.43deg, 
      rgb(133, 18, 224) 172.9deg, 
      rgb(69, 164, 236) 207.19deg, 
      rgb(89, 241, 245) 244.06deg, 
      rgb(37, 193, 111) 291.15deg, 
      rgb(37, 193, 111) 323.3deg, 
      rgb(239, 199, 94) 373.28deg
    );
    -webkit-mask: linear-gradient(rgb(255, 255, 255) 0px, rgb(255, 255, 255) 0px) padding-box padding-box, linear-gradient(rgb(255, 255, 255) 0px, rgb(255, 255, 255) 0px);
    -webkit-mask-composite: destination-out;
  }
  
  @media (max-width: 768px) {
    .boxxx-container {
      flex-direction: column;
    }
  }
  